var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"20%","width":"100%"}},[_vm._v(" 系统当前学期: "),_c('el-select',{ref:"add",attrs:{"placeholder":"请选择"},on:{"visible-change":(v) =>
                        _vm.visibleChange(
                          v,
                          'add',
                          //() => {
                           // categoriesClick(temp);//需要传递参数
                         // },
                         _vm.categoriesClick,//无需参数
                          '添加学期'
                        )},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.year,attrs:{"label":item.year,"value":item.year}})}),1),_c('el-button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.termSwitch}},[_vm._v("切换 ")]),_c('el-dialog',{attrs:{"title":"添加学期","visible":_vm.openOff,"width":"52%","append-to-body":""},on:{"update:visible":function($event){_vm.openOff=$event}}},[_c('childrenVue',{attrs:{"options":_vm.options},on:{"listenToChildEvent":_vm.showMsgfromChild,"closeHandle":_vm.closeHandle}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }