import requst from '@/utils/request.js'

// 查询所有实验室信息
export const getLabInfo = () => {
    return requst.get('/Lab/getAll')
}

// 查询当前学期所有课表课程信息
export const getScheduleInfo = (year) => {
    return requst.get(`/course/labSchedules/${year}`)
}