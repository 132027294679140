import requst from '@/utils/request.js'

//申请维修实验室
export const applyANewRepair = (form) => {
    return requst.post('/apply/add/repair', form)
}

export const getRepairById = (repairId) => {
    return requst.get('/get/repair?repairId=' + repairId)
}

