import request from "@/utils/request";
import requst from "@/utils/request";

export const getLabRepair = (currentTerm, curId) => {
    return request.get('/repair?currentTerm=' + currentTerm + '&curId=' + curId)
}

export const startLabRepair = (equipmentRepairId) => {
    return request.put('/repair/start?equipmentRepairId=' + equipmentRepairId)
}

export const finishLabRepair = (repairStateId, descriptionOfRepair, equipmentRepairId) => {
    return request.put('/repair/finish?repairStateId=' + repairStateId + '&descriptionOfRepair=' + descriptionOfRepair + '&equipmentRepairId=' + equipmentRepairId)
}

//根据教师id查看申报维修记录
export const getAllApplicationRepairByTeacherId = (teacherId) => {
    return requst.get('/repair/application?teacherId=' + teacherId)
}

//根据教师id和申报状态查询记录
export const getApplicationByTeacherIdAndRepairStatusName = (teacherId, repairStatusName) => {
    return requst.get('/repair/get/application?teacherId=' + teacherId + '&repairStatusName=' + repairStatusName)
}