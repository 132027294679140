<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.name" v-for="item in tab" :key="item.id">
            <el-table
                    :data="showData"
                    border
                    :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }"
                    height="86vh"
                    style="width: 100%"
            >
                <el-table-column
                        prop="rootNum"
                        label="实验室编号"
                        width="100%">
                </el-table-column>
                <el-table-column
                        prop="studentName"
                        label="申请学生"
                        width="100%">
                </el-table-column>
                <el-table-column
                        prop="year"
                        label="学期"
                        width="110%">
                </el-table-column>
                <el-table-column
                        prop="startWeek"
                        label="起始周"
                        width="80%">
                </el-table-column>
                <el-table-column
                        prop="endWeek"
                        label="结束周"
                        width="80%">
                </el-table-column>
                <el-table-column
                        prop="section"
                        label="节次"
                        width="80%">
                </el-table-column>
                <el-table-column
                        prop="reason"
                        label="申请原因"
                        width="210%">
                </el-table-column>
                <el-table-column
                    style="text-align: center"
                    prop="dateTime"
                    label="填报日期"
                    width="160%">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="80%">
                </el-table-column>
                <el-table-column
                        label="操作"
                        width="260%">
                    <template slot-scope="scope" >
                       <div>
                        <div v-if="scope.row.status==='审核中'">
                            <el-button type="primary" size="small" @click="agreeBorrow(scope.row)">通 过</el-button>
                            <el-button type="danger" size="small" @click="rejectBorrow(scope.row)">不通过</el-button>
                        </div>
                           <div v-else-if="scope.row.status!=='审核中'" style="color: limegreen;font-weight: bolder">已审批</div>
                       </div>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            </el-tabs>
    </div>
</template>


<script>
    import {
        exitCourse,
    } from '@/api/course/course.js'
    import {agreeStuBorrow, getBorrowExamination, rejectStuBorrow} from "@/api/borrow/borrow-examination";
    import { getUserInfo } from '@/utils/storage.js'
    export default {
        data() {
            return  {
                studentId: '',    //学生id
                tableData: [],
                finishData:[],
                waitData:[],
                showData:[],
                lab:null,
                startWeek:'',
                endWeek:'',
                section:'',
                year:'',
                reason:'',
                rootNum:'',
                newLab:null,
                studentName:'',
                //tab数据项
                tab: [
                    {
                        id: '1',
                        label: '待审批',
                        name: 'await'
                    },
                    {
                        id: '2',
                        label: '已审批',
                        name: 'already'
                    }
                ],
                activeName: 'await',
                isShowButton: true,//审批按钮显示
                dialogFormVisible: false,
            }
        },
        created() {
            this.studentId = getUserInfo().id
            this.studentName=getUserInfo().name;
            this.getAllSelectedCourse()
        },
        methods: {
            handleClick(tab) {
                const name = tab.$options.propsData.name
                switch(name) {
                    case this.tab[0].name:
                        this.isShowButton = 'true'
                        this.showData=this.waitData
                        break
                    case this.tab[1].name:
                        this.isShowButton = 'false'
                        this.showData=this.finishData
                        break
                    default:
                        this.showData=this.waitData
                        break
                }
            },

            //是否同意学生的申请
            async agreeBorrow(row) {
                await agreeStuBorrow(row.labBorrowingId).then(res=>{
                    console.log(res);
                    if(res.data.code===0){
                        this.$confirm(res.data.message, '通过失败', {
                            confirmButtonText: '确定',
                            type: 'warning',
                            showCancelButton:false,
                            showClose:false
                        })
                    }else{
                        this.$message({
                            type: 'success',
                            message: '审批通过成功！'
                        })
                    }
                })
                await this.getAllSelectedCourse();
            },
            //不同意学生的申请
            async rejectBorrow(row) {
                await rejectStuBorrow(row.labBorrowingId).then(res=>{
                    console.log(res);
                    if(res.data.code===0){
                        this.$confirm(res.data.message, '通过失败', {
                            confirmButtonText: '确定',
                            type: 'warning',
                            showCancelButton:false,
                            showClose:false
                        })
                    }else{
                        this.$message({
                            type: 'success',
                            message: '审批驳回成功！'
                        })
                    }
                })
                await this.getAllSelectedCourse();
            },
            //根据学生id查询选课信息
            async getAllSelectedCourse() {
                this.waitData=[];
                this.finishData=[];
                const result = await getBorrowExamination();
                if (result.data.code === 1) {
                    this.tableData = result.data.data
                    this.tableData.forEach(item => {
                        if (item.status === "审核中") {
                             this.waitData.push(item);
                        } else {
                            this.finishData.push(item);
                        }
                    })
                    this.showData=this.waitData;
                }
            },
            //退课
            async exitCourse(row) {
                const form = {
                    courseId: row.courseId,
                    studentId: this.studentId
                }
                const result = await exitCourse(form)
                console.log(result)
            },
            getCurrentDate() {
                let now = new Date();
                let year = now.getFullYear();
                let month = now.getMonth() + 1;
                let day = now.getDate();
                return year + "-" + month + "-" + day;
            }


        },
    }
</script>


<style>

</style>