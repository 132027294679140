<!-- 课程申请表单 -->
<template>
    <div class="root">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="实验室编号：">
                <el-select v-model="form.labNum" placeholder="实验室编号" style="width: 100%;">
                    <el-option v-for="item in labNum" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="故障描述：">
                <el-input  type="textarea" v-model="form.descriptionOfFault" placeholder="故障描述" :rows="8" style="width: 100%;">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="onSubmit" style="width: 140px; position: relative;right: 20px;">提 交</el-button>
                <!-- <el-button>保 存</el-button> -->
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getUserInfo } from '@/utils/storage.js'
import {applyANewRepair} from "@/api/repair/application-repair";
import {getCurrentTerm} from "@/api/member/term-manager";

function getCurrentDate() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    return year + "-" + month + "-" + day;
}

export default {
    data() {
        return {
            //可以取的学分
            labNum:['732','734','704','708','806','808'],
            //表单数据
            form: {
                teacherId: '',                      //教师id
                teacherName:'',                     //教师名称
                labNum:'',                         //实验室编号
                descriptionOfFault:'',                       //故障描述
                dateTime:'',                        //报修日期
                year:''
            }
        }
    },
    async created() {
        this.form.teacherId = getUserInfo().id
        this.form.teacherName = getUserInfo().name
        this.form.dateTime = getCurrentDate();
        await getCurrentTerm().then(res => {
            this.form.year = res.data;
        });
    },
    methods: {
        async submitInfo() {
            const result = await applyANewRepair(this.form)
            console.log(result)
            console.log(this.form)
        },

        onSubmit() {
            this.$confirm('您确定提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(
                async () => {
                        this.submitInfo(),
                            this.form.labNum = '',
                            this.form.descriptionOfFault = '',
                            this.$message({
                                type: 'success',
                                message: '提交成功!'
                            })

                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                })
            })
        },

    }
}
</script>

<style scoped>
.root {
    width: 60vh;
}
</style>