<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="item.label" :name="item.name" v-for="item in tab" :key="item.id">
        <el-table
            :data="showData"
            border
            :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }"
            height="86vh"
            style="width: 100%"
        >
          <el-table-column
              prop="labNum"
              label="实验室编号"
              width="100%">
          </el-table-column>
          <el-table-column
              prop="teacherName"
              label="申请老师"
              width="210%">
          </el-table-column>
          <el-table-column
              prop="descriptionOfFault"
              label="申请原因"
              width="210%">
          </el-table-column>
          <el-table-column
              v-if="isRepairedDescription==='true'"
              prop="descriptionOfRepair"
              label="维修结果"
              width="210%">
          </el-table-column>
          <el-table-column
              style="text-align: center"
              prop="dateTime"
              label="填报日期"
              width="160%">
          </el-table-column>
          <el-table-column
              label="操作"
              width="200%">
            <template slot-scope="scope" >
              <div>
                <div v-if="scope.row.repairStatusId===1">
                  <el-button type="primary" size="small" @click="startRepair(scope.row)">维 修</el-button>
                </div>
                <div v-else-if="scope.row.repairStatusId===2">
                  <el-button type="primary" size="small" @click="getInfoById(scope.row, 3)">完 成</el-button>
                  <el-button type="primary" size="small" @click="getInfoById(scope.row, 4)">无法维修</el-button>
                </div>
                <div v-else-if="scope.row.repairStatusId===3" style="color: limegreen;font-weight: bolder">已维修</div>
                <div v-else-if="scope.row.repairStatusId===4" style="color: red;font-weight: bolder">无法维修</div>
                <div v-else-if="scope.row.repairStatusId!==(3 || 4)" style="color: grey;font-weight: bolder">未知</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="title" :visible.sync="dialogFormEditionVisible" style="width: 50%; margin: 0 auto;">
      <el-form :model="form">
        <el-form-item>
          <el-input v-model="form.descriptionOfRepair" autocomplete="off" style="width: 350px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormEditionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateInfo()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>


<script>
import {
  finishLabRepair,
  getLabRepair, startLabRepair,
} from '@/api/repair/lab-repair.js'
import {getCurrentTerm} from "@/api/member/term-manager";
import {getUserInfo} from "@/utils/storage";
export default {
  data() {
    return  {
      tableData: [],
      finishData:[],
      processData: [],
      waitData:[],
      showData:[],
      //tab数据项
      tab: [
        {
          id: '1',
          label: '未维修',
          name: 'await'
        },
        {
          id: '2',
          label: '维修中',
          name: 'processing'
        },
        {
          id: '3',
          label: '已维修',
          name: 'already'
        }
      ],
      form: {
          descriptionOfRepair: '',
          equipmentRepairId: '',
          repairStatusId: '',
      },
      dialogFormEditionVisible: false,
      activeName: 'await',
      title: '维修结果',
      isShowButton: true,//维修按钮显示
      isRepairedDescription: false,
      dialogFormVisible: false,
    }
  },
  created() {
    this.getAllRepair()
  },
  methods: {
    handleClick(tab) {
      const name = tab.$options.propsData.name
      switch(name) {
        case this.tab[0].name:
          this.isShowButton = 'true'
          this.isRepairedDescription = 'false'
          this.showData=this.waitData
          break
        case this.tab[1].name:
          this.isShowButton = 'true'
          this.isRepairedDescription = 'false'
          this.showData=this.processData
          break
        case this.tab[2].name:
          this.isShowButton = 'false'
          this.isRepairedDescription = 'true'
          this.showData=this.finishData
          break
        default:
          this.showData=this.waitData
          break
      }
    },
    //开始维修
    async startRepair(row) {
      await startLabRepair(row.equipmentRepairId).then(res => {
        console.log(res);
        if(res.data.code===1){
            this.$message({
                type: 'success',
                message: '开始该维修'
            })
        } else {
            this.$message({
                type: 'warning',
                message: '更改维修状态失败'
            })
        }
      })
      await this.getAllRepair();
    },
    // async finishRepair(e) {
    //   await finishLabRepair(3, 'finishTest', row.equipmentRepairId).then(res => {
    //       console.log(res);
    //       if (res.data.code===1){
    //           this.$message({
    //               type: 'success',
    //               message: '该维修已完成'
    //           })
    //       } else {
    //           this.$message({
    //               type: 'warning',
    //               message: '更改维修状态失败'
    //           })
    //       }
    //   })
    //   await this.getAllRepair();
    // },
    // async failRepair(row) {
    //   await finishLabRepair(4, 'failTest', row.equipmentRepairId).then(res => {
    //     console.log(res);
    //     if (res.data.code===1){
    //       this.$message({
    //         type: 'success',
    //         message: '维修状态已更改'
    //       })
    //     } else {
    //       this.$message({
    //         type: 'warning',
    //         message: '更改维修状态失败'
    //       })
    //     }
    //   })
    // },
    //根据学生id查询选课信息
    async getAllRepair() {
      this.waitData=[];
      this.processData=[];
      this.finishData=[];
      const curTerm = await getCurrentTerm();
      const curId = await getUserInfo().id;
      const result = await getLabRepair(curTerm.data, curId);
      console.log(curId);
      if (result.data.code === 1) {
        this.tableData = result.data.data
        console.log(result.data)
        this.tableData.forEach(item => {
          if (item.repairStatusId === 1) {
            this.waitData.push(item);
          } else if (item.repairStatusId === 2) {
            this.processData.push(item);
          } else {
            this.finishData.push(item);
          }
        })
        this.showData=this.waitData;
      }
    },
    async getInfoById(e, s) {
      const result = e;
      console.log(result)
      this.title = '维修结果'
      this.form.descriptionOfRepair = e.descriptionOfRepair
      this.form.equipmentRepairId = e.equipmentRepairId
      this.form.repairStatusId = s
      this.dialogFormEditionVisible = true
    },
    //编辑之后更新维修结果
    async updateInfo() {
      const result = await finishLabRepair(this.form.repairStatusId, this.form.descriptionOfRepair, this.form.equipmentRepairId)
      console.log(result)
      this.dialogFormEditionVisible = false
      // this.getInfo()
      this.$message({
        type: 'success',
        message: '操作成功!'
      })
      await this.getAllRepair();
      this.showData = this.processData;
    },
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      return year + "-" + month + "-" + day;
    }

  },
}
</script>


<style>

</style>