<template>
  <div>
    <div v-if="flag==0">
      <div style="display: flex;
  flex-wrap: nowrap;">
        <p style="margin-left: 350px">已申请实验室</p>
        <el-button type="primary" style="margin-left: 350px" @click="add">新增申请</el-button>
      </div>
      <el-table
             :data="tableData"
             border
             :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }"
             height="86vh"
         >
           <el-table-column
               prop="rootNum"
               label="实验室编号"
               width="100%">
           </el-table-column>
           <el-table-column
               prop="year"
               label="学期"
               width="130%">
           </el-table-column>
           <el-table-column
               prop="startWeek"
               label="起始周"
               width="80%">
           </el-table-column>
           <el-table-column
               prop="endWeek"
               label="结束周"
               width="80%">
           </el-table-column>
           <el-table-column
               prop="section"
               label="节次"
               width="80%">
           </el-table-column>
           <el-table-column
               prop="status"
               label="状态"
               width="80%">
           </el-table-column>
           <el-table-column
               prop="reason"
               label="申请原因"
               width="200%">
           </el-table-column>
           <el-table-column
               style="text-align: center"
               prop="dateTime"
               label="填报日期"
               width="95%">
           </el-table-column>
           <el-table-column
               fixed="right"
               label="操作"
               width="100%">
             <template slot-scope="scope">
               <h2 v-if="scope.row.status=='审核中'">
                 <el-button
                     size="mini"
                     @click="handleEditCourse(scope.row)"
                     type="primary"
                     plain>编辑</el-button>
               </h2>
               <h2 v-if="scope.row.status=='通过'">
                 <el-button type="primary" size="mini" @click="open(scope.row)">
                   已使用
                 </el-button>
               </h2>
               <h2 v-if="scope.row.status=='使用完毕'">
                 <p style="font-size: 10px">使用完毕</p>
               </h2>
               <h2 v-if="scope.row.status=='驳回'">
                     <el-button
                         size="mini"
                         @click="handleEditCourse(scope.row)"
                         type="primary"
                         plain>编辑</el-button>
               </h2>
             </template>
           </el-table-column>
         </el-table>
     </div>
    <h2 v-if="flag==1">
    <div  style="position: absolute; top: 0;margin-top:120px;
    margin-left:150px ; border:1px solid #000;height: 500px;width: 500px" >
        <el-form  :model="lab" label-width="100px" style="margin-top: 40px;margin-left: 26px">
          <el-form-item label="申请实验室:">
            <el-select v-model="lab.rootNum">
              <el-option label="704" value="704"></el-option>
              <el-option label="708" value="708"></el-option>
              <el-option label="732" value="732"></el-option>
              <el-option label="734" value="734"></el-option>
              <el-option label="806" value="806"></el-option>
              <el-option label="808" value="808"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="起始周次:">
            <el-select v-model="lab.startWeek">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="结束周次:">
            <el-select v-model="lab.endWeek">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>

            </el-select>

          </el-form-item>
          <el-form-item label="节次:" >
            <el-select v-model="lab.section">
              <el-option label="1-2" value="1-2"></el-option>
              <el-option label="3-5" value="3-5"></el-option>
              <el-option label="6-7" value="6-7"></el-option>
              <el-option label="8-9" value="8-9"></el-option>
              <el-option label="10-12" value="10-12"></el-option>
              <el-option label="13-15" value="13-15"></el-option>
            </el-select>
            <!--<el-input  style="width:85%" v-model="lab.section"></el-input>-->
          </el-form-item>
        <!--  <el-form-item label="学期:">
            <el-input style="width:85%" v-model="lab.year"></el-input>
          </el-form-item>-->
          <el-form-item label="原因:">
            <el-input style="width:60%" v-model="lab.reason" type="textarea" :rows="4"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="gx" style="margin-left: 126px" type="primary">
          确定
        </el-button>
      <el-button @click="cz" style="margin-left: 40px" >
        恢复
      </el-button>
    </div>
      </h2>
    <h2 v-if="flag==2">
      <div  style="position: absolute; top: 0;margin-top:120px;
    margin-left:150px ; border:1px solid #000;height: 500px;width: 500px" >
        <el-form  :model="newLab" label-width="100px" style="margin-top: 40px;margin-left: 26px">
          <el-form-item label="申请实验室:">
            <el-select v-model="rootNum">
              <el-option label="704" value="704"></el-option>
              <el-option label="708" value="708"></el-option>
              <el-option label="732" value="732"></el-option>
              <el-option label="734" value="734"></el-option>
              <el-option label="806" value="806"></el-option>
              <el-option label="808" value="808"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="起始周次:">
            <el-select v-model="startWeek">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>


            </el-select>
          </el-form-item>
          <el-form-item label="结束周次:">
            <el-select v-model="endWeek">
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>


            </el-select>

          </el-form-item>
          <el-form-item label="节次:" >
            <el-select v-model="section">
              <el-option label="1-2" value="1-2"></el-option>
              <el-option label="3-5" value="3-5"></el-option>
              <el-option label="6-7" value="6-7"></el-option>
              <el-option label="8-9" value="8-9"></el-option>
              <el-option label="10-12" value="10-12"></el-option>
              <el-option label="13-15" value="13-15"></el-option>
            </el-select>
            <!--<el-input  style="width:85%" v-model="lab.section"></el-input>-->
          </el-form-item>
          <!--<el-form-item label="学期:">
            <el-input style="width:85%" v-model="year"></el-input>
          </el-form-item>-->
          <el-form-item label="原因:">
            <el-input style="width:60%" v-model="reason" type="textarea" :rows="4"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="add2" style="margin-left: 126px" type="primary">
          确定
        </el-button>
        <el-button @click="flag=0" style="margin-left: 40px" >
          取消
        </el-button>
      </div>
    </h2>

    </div>
</template>


<script>
import {addStudentLab, exitCourse, getAllSelectedCourse, updateStudentLab,accpetLab} from '@/api/course/course.js'
import { getUserInfo } from '@/utils/storage.js'
export default {
    data() {
        return  {
            studentId: '',    //学生id
            tableData: [],
            flag:0,
            lab:null,
            startWeek:'',
          endWeek:'',
           section:'',
          year:'',
          reason:'',
          rootNum:'',
          newLab:null,
          studentName:''
        }
    },
    created() {
        this.studentId = getUserInfo().id
      this.studentName=getUserInfo().name;
        this.getAllSelectedCourse()
    },
    methods: {
    open(row) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          function accept() {
           accpetLab(row);
          }
         accept(row);
          this.$message({
            type: 'success',
            message: '使用完毕!',
          });
          this.getAllSelectedCourse()
          location.reload();
        }).catch(() => {

        });
      },
        //根据学生id查询选课信息
        async getAllSelectedCourse() {
            const result = await getAllSelectedCourse(this.studentId)
            if (result.data.code === 200) {
                this.tableData = result.data.data
            }
        },
        //退课
        async exitCourse(row) {
            const form = {
                courseId: row.courseId,
                studentId: this.studentId
            }
            const result = await exitCourse(form)
            console.log(result)
        },

      //重置恢复成一开始的设置
      cz() {
        this.lab.rootNum=this.rootNum;
        this.lab.year=this.year;
        this.lab.section=this.section;
        this.lab.reason=this.reason;
        this.lab.startWeek=this.startWeek;
        this.lab.endWeek=this.endWeek;
      },
      //更新
      gx(){
        if(this.lab.startWeek>this.lab.endWeek)
      {
          console.log(this.lab.startWeek)
          console.log(this.lab.endWeek)

          this.$message({
              showClose: true,
              message: '结束时间不得早于开始时间',
              type: 'error'
          });
          return ;
      }
        this.lab.dateTime= this.getCurrentDate();
        this.lab.status="审核中";
        console.log(this.lab);
        updateStudentLab(this.lab);
        this.flag=0;
      },
        //编辑
        handleEditCourse(row) {
          this.lab=row;
          this.rootNum=row.rootNum;
          this.year=row.year;
          this.section=row.section;
          this.reason=row.reason;
          this.startWeek=row.startWeek;
          this.endWeek=row.endWeek;
          this.flag=1;
        },
      add(){
          this.endWeek=null;
          this.reason=null;
          this.section=null;
          this.startWeek=null;
          this.rootNum=null;
          this.year=null;
          this.flag=2;
        },
      add2(){
      if(this.startWeek>this.endWeek)
      {
        this.$message({
          showClose: true,
          message: '结束时间不得早于开始时间',
          type: 'error'
        });
        return ;
      }
          this.newLab=new Object();
          this.newLab.section=this.section;
          this.newLab.startWeek=this.startWeek;
          this.newLab.reason=this.reason;
          this.newLab.rootNum=this.rootNum;
          this.newLab.year=this.year;
        this.newLab.endWeek=this.endWeek;
        this.newLab.studentId=this.studentId;
        this.newLab.studentName=this.studentName;
        this.newLab.status="审核中";
        this.newLab.dateTime= this.getCurrentDate();
        console.log(this.newLab);
        addStudentLab(this.newLab);
        this.getAllSelectedCourse();
        location.reload();
        this.flag=0;
      },
      getCurrentDate() {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let h = now.getHours();
        let m = now.getMinutes();
        let s = now.getSeconds();
        return   `${year}-${month}-${day} ${h}:${m}:${s}`;
      }
    }

}
</script>


<style>

</style>