<template>
    <div style="margin-left: 80px">
        <el-input-number v-model="year1" :min="2023" :max="2060" @change="handleChange" > </el-input-number> —
        <el-input-number v-model="year2" :disabled="true" /> -
        <el-select v-model="semester" placeholder="学期" size="default" style="display: inline-block; width: 10%;">
        <el-option label="1" value="1"></el-option>
        <el-option label="2" value="2"></el-option>
        </el-select>

        <el-button
                style="margin-left: 40px"
                type="primary"
                size="small"
                @click="add"
        >新增</el-button>
        <el-button
                type="danger"
                size="small"
                @click="close"
        >关闭</el-button>
    </div>
</template>


<script>
    import {insertTermInfo} from "@/api/member/term-manager";

    export default {
        name:"newTerm",
        data() {
            return {
                year1:2023,
                year2:2024,
                semester:"1",
                msg:"",
            }
        },
        props:{
            options:[{}]
        },
        methods:{
            handleChange(){
                this.year2=this.year1+1;
            },
            close(){
                this.$emit("closeHandle");
            },
            async add(){
                this.msg = this.year1.toString() + "-" + this.year2.toString() + "-" + this.semester;
                await this.$alert('是否新增该学期' + this.msg, '新增学期', {
                    confirmButtonText: '确定',
                    callback: async action => {
                        if (action === "confirm") {
                            for (let i = 0; i < this.options.length; i++) {
                                if (this.options[i].year === this.msg) {
                                    this.$message({
                                        type: 'error',
                                        message: `已存在学期:${this.msg}，请修改后新增！`
                                    });
                                    return;
                                }
                            }
                            //新增一条term
                            let form = {year: ''};
                            form.year = this.year1.toString() + "-" + this.year2.toString() + "-" + this.semester;   //年份学期
                            console.log(form);
                            await insertTermInfo(form);
                            this.$emit('listenToChildEvent', this.year1.toString() + "-" + this.year2.toString() + "-" + this.semester)
                            this.$message({
                                type: 'success',
                                message: '新增成功!'
                            })
                            this.$message({
                                type: 'success',
                                message: `确认新增:${this.msg}`
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: `取消新增:${this.msg}`
                            });
                        }
                        this.close();
                    }
                });

            }
        }

        }
</script>
