import requst from '@/utils/request.js'

// 新增一条学期信息
export const insertTermInfo = (form) => {
    return requst.post('/term', form)
}

// 获取当前学期信息
export const getCurrentTerm = () => {
    return requst.get('/get/currentTerm',)
}

// 更新当前学期信息
export const updateCurrentTerm = (term) => {
    return requst.put('/update/currentTerm?term=' + term)
}

//获取所有学期的信息
export const getAllTerm = () => {
    return requst.get('/get/terms')
}