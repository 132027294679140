<!-- 首页 -->
<template>
    <div>
        <div class="block" style="background-color: #409fff;">
            <el-carousel height="160px">
            <el-carousel-item style="background-color: white;text-align: center">
                <img src="../assets/bg.jpg" height="170px" width="53%" >
            </el-carousel-item>
            <el-carousel-item style="text-align: center;">
                <img src="../assets/bg3.png" height="130px" width="72%">
            </el-carousel-item>
            <el-carousel-item style="text-align: center;">
                <img src="../assets/bg2.png" height="140px" width="68%">
            </el-carousel-item>
            </el-carousel>
        </div>
        <div style="
            font-size: 23px;
            font-weight: bolder;
            padding: 19px;
            display: flex;
            justify-content: center;
            align-items: center;">{{this.year}} 实验室排课表</div>
        <div class="tableWrap">
            <el-table
                    id="resultTableProject"
                    :data="dataList"
                    border
                    v-loading="loading"
                    element-loading-text="数据查询中"
                    :span-method="handleSpan"
                    height="620"
                    size='small'
            >
                <el-table-column
                        label="星期"
                        prop="weekday"
                        width="100"
                        align="center"
                />
                <el-table-column
                        label="实验室"
                        prop="labName"
                        width="160"
                        align="center"
                />
                <el-table-column
                        label="机房"
                        prop="labNum"
                        width="100"
                        align="center"
                />

                <el-table-column
                        label="1-2"
                        prop="p1"
                        align="center"
                />

                <el-table-column
                        label="3-5"
                        prop="p2"
                        align="center"
                />

                <el-table-column
                        label="6-7"
                        prop="p3"
                        align="center"
                />

                <el-table-column
                        label="8-9"
                        prop="p4"
                        align="center"
                />

                <el-table-column
                        label="10-12"
                        prop="p5"
                        align="center"
                />

                <el-table-column
                        label="13-15"
                        prop="p6"
                        align="center"
                />

            </el-table>

        </div>
    </div>
</template>

<script>
    import {getLabInfo, getScheduleInfo} from "@/api/home/home";
    import {getCurrentTerm} from "@/api/member/term-manager";

    export default {
        name: 'HomeView',
        data() {
            return {
                spanArr:[], // 某一列下需要合并的行数
                pos:0,// 索引
                dataList:[],
                term:"",
                passExamination:[],
                labInfo:[],
                year:""
            }
        },
        methods:{
            getList(){
                // const tableData=[
                //     {  weekday:"星期一",labName:'软件实验室',labNum:732,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期一",labName:'软件实验室',labNum:734,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期一",labName:'计算机系统实验室',labNum:704,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期一",labName:'计算机系统实验室',labNum:708,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期一",labName:'物联网实验室',labNum:806,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期一",labName:'物联网实验室',labNum:808,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'软件实验室',labNum:732,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'软件实验室',labNum:734,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'计算机系统实验室',labNum:704,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'计算机系统实验室',labNum:708,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'物联网实验室',labNum:806,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                //     {  weekday:"星期五",labName:'物联网实验室',labNum:808,p1:"",p2:"",p3:"",p4:"",p5:"",p6:""},
                // ]
                const  tableData=[];
                const weekday = ["星期一","星期二","星期三","星期四","星期五"];
                const time = ['1-2','3-5','6-7','8-9','10-12','13-15'];
                for(let i=0;i<5;i++){
                    for(let j=0;j<this.labInfo.length;j++){
                        tableData[j+i*this.labInfo.length] = {
                            weekday: weekday[i],
                            labName: this.labInfo[j].labName,
                            labNum: this.labInfo[j].labNum,
                            p1:"",
                            p2:"",
                            p3:"",
                            p4:"",
                            p5:"",
                            p6:""
                        }
                    }
                }
                for(let j=0;j<this.passExamination.length;j++){
                    switch(this.passExamination[j].time) {
                        case time[0]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                tableData[i].p1 = "课程：" + this.passExamination[j].courseName +
                                                  "\n教师：" + this.passExamination[j].teacherName +
                                                  "\n班级：" + this.passExamination[j].studentClass +
                                                  "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        case time[1]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                    tableData[i].p2 = "课程：" + this.passExamination[j].courseName +
                                        "\n教师：" + this.passExamination[j].teacherName +
                                        "\n班级：" + this.passExamination[j].studentClass +
                                        "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        case time[2]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                    tableData[i].p3 = "课程：" + this.passExamination[j].courseName +
                                        "\n教师：" + this.passExamination[j].teacherName +
                                        "\n班级：" + this.passExamination[j].studentClass +
                                        "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        case time[3]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                    tableData[i].p4 = "课程：" + this.passExamination[j].courseName +
                                        "\n教师：" + this.passExamination[j].teacherName +
                                        "\n班级：" + this.passExamination[j].studentClass +
                                        "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        case time[4]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                    tableData[i].p5 = "课程：" + this.passExamination[j].courseName +
                                        "\n教师：" + this.passExamination[j].teacherName +
                                        "\n班级：" + this.passExamination[j].studentClass +
                                        "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        case time[5]:
                            for (let i=0;i<tableData.length;i++){
                                if(tableData[i].weekday===this.passExamination[j].weekday && tableData[i].labNum===this.passExamination[j].labNum )
                                    tableData[i].p6 = "课程：" + this.passExamination[j].courseName +
                                        "\n教师：" + this.passExamination[j].teacherName +
                                        "\n班级：" + this.passExamination[j].studentClass +
                                        "\n周次：" + this.passExamination[j].weeks;
                            }
                            break;

                        default:
                            break;
                    }
                }
                console.log(tableData)

                this.getNewListM(tableData);
            },
            getNewListM(list) {
                let a = 1;
                let b = 0;
                for (let i = 1; i < list.length; i++) {
                    if (list[i].weekday === list[i - 1].weekday) {
                        a++;
                        list[b]['0'] = {row: a, col: 1};
                        list[i]['0'] = {row: 0, col: 1};
                    } else {
                        list[i]['0'] = {row: 1, col: 1};
                        a = 1;
                        b = i;
                    }
                }
                a = 1;
                b = 0;
                for (let i = 1; i < list.length; i++) {
                    if (list[i].labName === list[i - 1].labName) {
                        a++;
                        list[b]['1'] = {row: a, col: 1};
                        list[i]['1'] = {row: 0, col: 1};
                    } else {
                        list[i]['1'] = {row: 1, col: 1};
                        a = 1;
                        b = i;
                    }
                }
                this.dataList = list
            },

            handleSpan({row, columnIndex}) {
                let colinfo = row[columnIndex + ''];
                if (colinfo) {
                    return [colinfo.row, colinfo.col];
                }
            }
        },
        async created() {
            await getCurrentTerm().then(res=>{
                this.year=res.data;
            })
            await getLabInfo().then(res=>{
                this.labInfo = res.data.data;
            });
            console.log(this.year)
            await getScheduleInfo(this.year).then(res=>{
                this.passExamination = res.data.data;
                console.log(res.data)
            });
            await this.getList();
        }
    }


</script>
<style lang="less" scoped>
  .block div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .tableQualityProject {
    height: 100vh;
    width: 100%;
    overflow: auto;
    h1 {}
  }
  .tableWrap {
    //  width: 100%;
    // margin: 0 auto;
    /deep/ .el-table__header th {
      background-color: #409eff!important ; /* 设置表头颜色 */
      color: #fff; /* 设置表头文字颜色 */
      //  background-color:#FAFAFA;
      //  color:#252525;
      border:1px solid #f2f2f2;
    }
    /deep/ .el-table .cell {
      // font-weight: 700;
      font-size: 16px;
      padding: 0;
    }
    .gzlWrap {
      // border-bottom:1px solid #dfdfdf;
      &:last-child {
        border-bottom: none;
      }
      span {
        display: inline-block;
        padding: 5px 0;
      }
      .key {
        width: 60%;
      }
      .value {
        width: 40%;
      }
    }

    .selfTableHead {
      display: flex;
      span {
        display: inline-block;
        width: 60px;
      }
    }
  }

  .tableWrap::v-deep .el-table .cell {
    white-space: pre-line;
  }
</style>