<template>
    <div class="root">
        <el-tabs v-model="activeName" @tab-click="handleClick" style="user-select: none;">
            <el-tab-pane :label="item.label" :name="item.name" v-for="item in tab" :key="item.id">
                <div class="container">
                    <el-empty description="暂无数据" v-if="applicationData.length === 0"></el-empty>
                    <ApplicationRepairItem v-for="item in applicationData" :equipmentRepairId="item.equipmentRepairId"
                                           isShowButton="false" selectActive="false" @refresh="refresh" :key="item.equipmentRepairId" :if="applicationData.length > 0">
                        <template slot="userName">{{ item.teacherName }}</template>
                        <template slot="dateTime">{{ item.dateTime }}</template>
                        <template slot="repairStatusName">
                            <el-tag type="warming" size="mini" class="tag" v-if="item.repairStatusName === tab[1].label">
                                {{ item.repairStatusName }}
                            </el-tag>
                            <el-tag type="warming" size="mini" class="tag" v-if="item.repairStatusName === tab[2].label">
                                {{ item.repairStatusName }}
                            </el-tag>
                            <el-tag type="success" size="mini" class="tag" v-if="item.repairStatusName === tab[3].label">
                                {{ item.repairStatusName }}
                            </el-tag>
                            <el-tag type="danger" size="mini" class="tag" v-if="item.repairStatusName === tab[4].label">
                                {{ item.repairStatusName }}
                            </el-tag>
                        </template>

                    </ApplicationRepairItem>

                </div>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label"><i class="el-icon-edit-outline"></i> 申 请</span>
                <ApplicationRepairForm></ApplicationRepairForm>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ApplicationRepairForm from '@/views/repair/ApplicationRepairForm.vue'
import ApplicationRepairItem from '@/views/repair/ApplicaitonRepairItem.vue'

import { getUserInfo } from '@/utils/storage.js'
import {
    getAllApplicationRepairByTeacherId,
    getApplicationByTeacherIdAndRepairStatusName
} from "@/api/repair/lab-repair";
export default {
    data() {
        return {
            activeName: 'all',
            //tab数据项
            tab: [
                {
                    id: '1',
                    label: '全部',
                    name: 'all'
                },
                {
                    id: '2',
                    label: '未维修',
                    name: 'await'
                },
                {
                    id: '3',
                    label: '维修中',
                    name: 'pass'
                },
                {
                    id: '4',
                    label: '已维修',
                    name: 'no-pass'
                },
                {
                    id:'5',
                    label:'无法维修',
                    name:'insolubility'
                }
            ],
            //申请记录
            repairApplication: {
                equipmentRepairId: '',        //课程申请id
                teacherId: '',                 //教师id
                teacherName: '',                //教师名称
                dateTime:'',                    //报修日期
                /*courseId: '',                   //课程id
                courseName: '',                 //课程名称*/
                /*courseExaminationId: '',        //审批状态id
                courseExaminationName: '',      //审批状态名称*/
                repairStatusId: '',                //操作id
                repairStatusName: '',              //操作名称
            },
            applicationData: []                 //申请记录
        }
    },
    components: {
        ApplicationRepairForm,
        ApplicationRepairItem
    },
    async created() {
        const userInfo = getUserInfo()
        this.repairApplication.teacherId = userInfo.id
        this.getAllApplication()
    },
    methods: {
        //更新页面
        refresh() {
            console.log(this.activeName)
            for (let i = 0; i < this.tab.length; i++) {
                if (this.tab[i].name === this.activeName) {
                    this.getCoditionApplication(this.tab[i].label)
                    break;
                }
            }
        },
        //获取全部审批项
        async getAllApplication() {
            const result = await getAllApplicationRepairByTeacherId(this.repairApplication.teacherId)
            console.log(result)
            if (result.data.code === 1) {
                this.applicationData = result.data.data
            }
        },
        //根据条件获取相应审批项
        async getCoditionApplication(repairStatusName) {
            const teacherId = this.repairApplication.teacherId
            const result = await getApplicationByTeacherIdAndRepairStatusName(teacherId,repairStatusName)
            if (result.data.code === 1) {
                this.applicationData = result.data.data
            }
        },
        //tab栏切换事件
        handleClick(tab) {
            console.log(tab.$options.propsData.name)
            const name = tab.$options.propsData.name
            switch(name) {
                case this.tab[0].name:
                    this.getAllApplication()
                    break
                case this.tab[1].name:
                    this.getCoditionApplication(this.tab[1].label)
                    break
                case this.tab[2].name:
                    this.getCoditionApplication(this.tab[2].label)
                    break
                case this.tab[3].name:
                    this.getCoditionApplication(this.tab[3].label)
                    break
                case this.tab[4].name:
                    this.getCoditionApplication(this.tab[4].label)
                    break
                default:
                    break
            }
        },

    }
}
</script>

<style scoped>
.container {
    overflow-y: auto;
    height: 76vh;
    width: 100%;
}
</style>
