<template>
    <div style="display: flex;align-items: center;justify-content: center; height: 20%;width: 100%">
        系统当前学期:
<el-select
        v-model="value"
        placeholder="请选择"
        ref="add"
        @visible-change="
                      (v) =>
                        visibleChange(
                          v,
                          'add',
                          //() => {
                           // categoriesClick(temp);//需要传递参数
                         // },
                         categoriesClick,//无需参数
                          '添加学期'
                        )
                    "
>
    <el-option
            v-for="item in options"
            :key="item.year"
            :label="item.year"
            :value="item.year"
    />
</el-select>

<el-button
            style="margin-left: 15px"
            type="primary"
            size="large"
            @click="termSwitch"
 >切换
</el-button>

<el-dialog
        title="添加学期"
        :visible.sync="openOff"
        width="52%"

        append-to-body
>
    <childrenVue :options="options" v-on:listenToChildEvent = 'showMsgfromChild'
            @closeHandle="closeHandle">
    </childrenVue>
</el-dialog>
    </div>
</template>

<script>
    import childrenVue from "../term/newTerm.vue";
    import {getAllTerm, getCurrentTerm, updateCurrentTerm} from "@/api/member/term-manager";
    export default {
        name:"termManagement",
        components: { childrenVue },
        data() {
            return {
                openOff:false,
                options: [],
                value: '',
            }
        },
        methods:{
// 关闭弹窗（传递给子组件处理弹窗关闭）
            closeHandle() {
                this.openOff = false;
                this.getTerm();
            },
            // 弹出添加子选项弹窗(value未传递的参数)
            categoriesClick() {
                this.openOff = true;
                // console.log(value);
            },
            // 在下拉框处显示添加按钮
            visibleChange(visible, refName, onClick, addname) {
                if (visible) {
                    const ref = this.$refs[refName];
                    let popper = ref.$refs.popper;
                    if (popper.$el) popper = popper.$el;
                    if (
                        !Array.from(popper.children).some(
                            (v) => v.className === "el-select-dropdown__item"
                        )
                    ) {
                        const el = document.createElement("ul");
                        el.className = "el-select-dropdown__item";
                        el.style =
                            "border-top: solid 1px #E4E7ED; padding:0; color: #606266;";
                        el.innerHTML =
                            `<span class="el-select-dropdown__item"><i class="font-blue el-icon-plus"></i>` +
                            addname +
                            `</span>`;
                        popper.appendChild(el);
                        el.onclick = () => {
                            // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
                            onClick && onClick();

                            // 以下代码实现点击后弹层隐藏 不需要可以删掉
                            // if (ref.toggleDropDownVisible) {
                            //     ref.toggleDropDownVisible(false);
                            // } else {
                            //     ref.visible = false;
                            // }
                        };
                    }
                }
            },
            showMsgfromChild(data){
                console.log(data);
                this.getTerm();
            },
            async termSwitch(){
                console.log(this.value);
                const result = await getCurrentTerm();
                if(this.value === result.data) {
                    await this.$alert('学期未更改，请重新选择!', '切换学期', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            return action;
                        }
                    });
                }else {
                    await this.$alert('是否切换至该学期' + this.value + '，设置后平台其他的所有功能都是指在“当前学期”的工作!', '切换学期', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: async action => {
                            if (action === "confirm") {
                                await updateCurrentTerm(this.value);
                                await this.getTerm();
                                this.$message({
                                    type: 'success',
                                    message: `确认切换到:${this.value}`
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: `取消切换到:${this.value}`
                                });
                            }
                        }
                    });
                }
            },
            //获取所有学期信息和当前学期
            async getTerm() {
                const result1 = await getAllTerm();
                const result2 = await getCurrentTerm();
                this.options = result1.data.data;
                console.log(result2)
                this.value = result2.data;
                console.log(this.value);
            },
        },
        created() {
            this.getTerm();
        },

    }
</script>
