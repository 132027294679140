<template>
  <div class="root">

    <!-- 搜素栏 -->
    <el-row  :gutter="20">
      <el-col :span="6">
        <div>
          <span style="vertical-align: middle;">学号：</span>
          <el-input
            placeholder="请输入学号"
            style="display: inline-block; width: 50%; vertical-align: middle;"
            size="small"
            v-model="queryStudentParam.param.studentNumber"
            >
          </el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div>
          <span style="vertical-align: middle;">姓名：</span>
          <el-input
            placeholder="请输入姓名"
            style="display: inline-block; width: 60%; vertical-align: middle;"
            size="small"
            v-model="queryStudentParam.param.name"
            >
          </el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div>
          <span style="vertical-align: middle;">班级：</span>
          <el-input
            placeholder="请输入班级"
            style="display: inline-block; width: 60%; vertical-align: middle;"
            size="small"
            v-model="queryStudentParam.param.studentClass"
            >
          </el-input>
        </div>
      </el-col>
      <el-col :span="8">
        <div style="margin-left: -130px">
          <el-button type="primary" size="small" @click="getInfo">查 询</el-button>
          <el-button type="primary" size="small" @click="openDialog()">新 增</el-button>
            <el-button :type="upload4.status" icon="el-icon-plus" size="small"
                       @click="handleSImport">批量导入</el-button>
          <el-button type="danger" @click="deleteSelectedData()" plain :disabled ="selectionStatus" size="small">删 除</el-button>
        </div>
      </el-col>
    </el-row>

    <!-- 数据表格 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      height="70vh"
      @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
            prop="studentNumber"
            label="学号"
            width="200%">
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="200%">
        </el-table-column>
        <el-table-column
            prop="studentClass"
            label="班级"
            width="200%">
        </el-table-column>
        <el-table-column
            prop="profession"
            label="专业"
            width="300%">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作">
            <template slot-scope="scope">
            <el-button
              size="mini"
              @click="getInfoById(scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryStudentParam.currentPage"
      :page-sizes="[5,10, 15, 20, 25]"
      :page-size="queryStudentParam.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin-top: 14px;">
    </el-pagination>

    <!-- 对话框（新增） -->
    <el-dialog :title="title" :visible.sync="dialogFormAddVisible" style="width: 50%; margin: 0 auto;">
      <el-form :model="form">
        <el-form-item label="学号：" label-width="60px">
          <el-input v-model="form.studentNumber" autocomplete="off"  style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" label-width="60px">
          <el-input v-model="form.name" autocomplete="off"  style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="班级：" label-width="60px">
          <el-input v-model="form.studentClass" autocomplete="off" style="width: 250px;"></el-input>
        </el-form-item>
          <el-form-item label="专业：" label-width="60px">
              <el-input v-model="form.profession" autocomplete="off" style="width: 250px;"></el-input>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="insertInfo()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 对话框（编辑） -->
    <el-dialog :title="title" :visible.sync="dialogFormEditionVisible" style="width: 50%; margin: 0 auto;">
      <el-form :model="form">
        <el-form-item label="学号：" label-width="60px">
          <el-input v-model="form.studentNumber" autocomplete="off"  style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" label-width="60px">
          <el-input v-model="form.name" autocomplete="off"  style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="班级：" label-width="60px">
          <el-input v-model="form.studentClass" autocomplete="off" style="width: 250px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormEditionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateInfo()">确 定</el-button>
      </div>
    </el-dialog>

      <!-- 学生数据导入对话框 -->
      <el-dialog :title="upload4.title" :visible.sync="upload4.open" width="400px">
          <el-upload ref="upload4" :limit="1" accept=".xlsx, .xls" :headers="upload4.headers"
                     :action="upload4.url + '?updateSupport=' + upload4.updateSupport" :disabled="upload4.isUploading"
                     :on-progress="handleSFileUploadProgress" :on-success="handleSFileSuccess" :auto-upload="false" drag>

              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                  <el-checkbox v-model="upload4.updateSupport" />是否更新已经存在的用户数据
                  <div style="float:right;font-size: 18px">
                      <i class="el-icon-download"></i>
                      <el-link type="success" @click="importSTemplate">下载模板</el-link>
                  </div>
              </div>
              <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
          </el-upload>
          <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitSFileForm">确 定</el-button>
              <el-button @click="upload4.open = false">取 消</el-button>
          </div>
      </el-dialog>

  </div>
</template>
  
<script>
  import { 
    getStudentsInfo, 
    insertStudentInfo, 
    deleteStudentInfo,
    getStudentInfoById,
    updateStudentInfoById,
    // downloadTemp,
  } from '../../api/member/stduent-manager.js'
  import axios from "axios";
  import {getUserLoginToken} from "@/utils/storage";
  import {Loading, Message} from 'element-ui'
  // import { saveAs } from 'file-saver'

  export default {
    data() {
      return {
        //查询参数
        queryStudentParam: {
          pageSize: 10,                   //页面的大小，默认为10
          currentPage: 1,                 //现在所处第几页
          param: {
            studentNumber: '',            //学号
            name: '',                     //姓名
            studentClass: '',              //班级
            profession: ''                  //专业
          }
        },
        selectedDataId: [],               //被选中的数据id
        selectionStatus: true,            //按钮的状态,false表示可以点击删除,true表示不能点击删除
        dialogFormAddVisible: false,      //控制新增的对话框是否显示
        dialogFormEditionVisible: false,  //控制编辑的对话框是否显示
        total: -1,                        //合计数据    
        tableData: [],                    //表格数据 
        //表单数据
        title: '新增',
        form: {
          studentId: '',                  //学生id
          studentNumber: '',              //学号
          name: '',                       //姓名
          studentClass: '',                //班级
          profession: ''                  //专业
        },
          //学生批量导入参数
          upload4: {
              // 按钮样式
              status: "info",
              // 是否显示弹出层（学生批量导入）
              open: false,
              // 弹出层标题（学生批量导入）
              title: "",
              // 是否禁用上传
              isUploading: false,
              // 是否更新已经存在的用户数据
              updateSupport: 0,
              // // 设置上传的请求头部
              headers: { Authorization: getUserLoginToken() },
              // 上传的地址
              url: "http://127.0.0.1:8080/student/importData"
          },
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      //向后台获取信息
      async getInfo() {
        const result = await getStudentsInfo(this.queryStudentParam)
        this.tableData = result.data.data
        this.total = result.data.total
        console.log(result)
      },

      //处理页面大小的改变
      handleSizeChange(e) {
        this.queryStudentParam.pageSize = e
        this.getInfo()
      },
      //处理当前页数的改变
      handleCurrentChange(e) {
        console.log(e)
        this.queryStudentParam.currentPage = e
        this.getInfo()
      },

      //打开新增对话框
      openDialog() {
        this.title = '新增'
        this.form.studentNumber = '',
        this.form.name = '',
        this.form.studentClass = '',
        this.form.profession = ''
        this.dialogFormAddVisible = true
      },
      //新增一条学生信息 
      async insertInfo() {
        const result = await insertStudentInfo(this.form)
        console.log(result)
        this.dialogFormAddVisible = false
        this.$message({
            type: 'success',
            message: '新增成功!'
          })
        this.getInfo()
      },

      //根据id查询学生信息,打开编辑对话框
      async getInfoById(e) {
        const result = await getStudentInfoById(e.studentId)
        console.log(result)
        this.title = '编辑'
        this.form.studentId = result.data.data.studentId
        this.form.studentNumber = result.data.data.studentNumber
        this.form.name = result.data.data.name
        this.form.studentClass = result.data.data.studentClass
        this.form.profession = result.data.data.profession
        this.dialogFormEditionVisible = true
      },
      //编辑之后更新一条学生信息
      async updateInfo() {
        const result = await updateStudentInfoById(this.form)
        console.log(result)
        this.dialogFormEditionVisible = false
        this.getInfo()
        this.$message({
            type: 'success',
            message: '操作成功!'
        })
      },

      //删除一条学生信息
      handleDelete(row) {
        console.log(row)
        this.$confirm('您确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {   
          const result = await deleteStudentInfo(row.studentId)
          console.log(result)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getInfo()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })        
        })
      },
      
      //处理数据的选择情况
      handleSelectionChange(e) {
        console.log(e)
        if (e.length > 0) {
          this.selectionStatus = false
        } else {
          this.selectionStatus = true
        }
        this.selectedDataId = []
        for (let i = 0; i < e.length; i++) {
          this.selectedDataId.push(e[i].studentId)
        }
        console.log(this.selectedDataId)
      },
      //删除所选中的数据
      deleteSelectedData() {
        this.$confirm('您确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const result = await deleteStudentInfo(this.selectedDataId)
          console.log(result)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getInfo()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })        
        })
      },
        /** 导入学生数据按钮操作 */
        handleSImport() {
            this["upload4"].title = "批量导入学生数据";
            this.upload4.open = true;
        },

        /** 下载导入学生数据模板操作 */
        importSTemplate() {
            let downloadLoadingInstance;
            downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
            console.log(getUserLoginToken());
            axios({
                url: 'http://127.0.0.1:8080/student/importTemplate',
                method: 'POST',
                responseType: 'blob',  // 表示响应数据是一个 Blob 对象
                headers: {
                    'Authorization':  getUserLoginToken()
                },
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `学生批量导入模板${new Date().getTime()}.xlsx`); // 或者其他你想要的文件名
                document.body.appendChild(link);
                link.click();
                downloadLoadingInstance.close();
            }).catch((r) => {
                console.error(r)
                Message.error('下载文件出现错误，请联系管理员！')
                downloadLoadingInstance.close();
            })
        },

        // 导入学生数据文件上传中处理
        handleSFileUploadProgress() {
            this.upload4.isUploading = true;
        },
        // 导入学生数据文件上传成功处理
        handleSFileSuccess(response) {
            this.upload4.open = false;
            this.upload4.isUploading = false;
            this.$refs.upload4.clearFiles();
            this.$alert(response.msg, "导入结果", { dangerouslyUseHTMLString: true });
            if(response.code == '200'){
                this.upload4.status = "success";
                this.getInfo();
            }
        },
        // 导入学生数据提交上传文件
        submitSFileForm() {
            this.$refs.upload4.submit();
        },

    }
  }
</script>

<style scoped>
  .root {
    text-align: center;
  }
  .el-row {
    margin-bottom: 16px;
  }

</style>