<!-- 课程申请表单 -->
<template>
    <div class="root">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="课程名称：">
                <el-input v-model="form.courseName" placeholder="请输入课程名称"/>
            </el-form-item>
            <el-form-item label="实验室类型：">
                <el-select v-model="form.labType" placeholder="请选择实验室类型" style="width: 100%;">
                    <el-option v-for="item in labType" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="学生班级：">
                <el-input v-model="form.studentClass" placeholder="请输入学生班级"/>
            </el-form-item>
            <el-form-item label="学生人数：">
                <el-input type="Number" v-model="form.studentNum" placeholder="请输入学生人数"/>
            </el-form-item>
            <el-form-item label="星期：">
                <el-select v-model="form.weekday" placeholder="请选择星期" style="width: 100%;">
                    <el-option v-for="item in weekdays" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="节次：">
                <el-select v-model="form.time" placeholder="请选择节次" style="width: 100%;">
                    <el-option v-for="item in time" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="起始周：">
                <el-select v-model="form.startWeek" placeholder="请选择起始周" style="width: 100%;">
                    <el-option v-for="item in startWeek" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>
            <el-form-item label="结束周：">
                <el-select v-model="form.endWeek" placeholder="请选择结束周" style="width: 100%;">
                    <el-option v-for="item in endWeek" :label="item" :value="item" :key="item"/>
                </el-select>
            </el-form-item>

            <el-form-item style="text-align: center;">
                <el-button type="primary" @click="onSubmit" style="width: 140px; position: relative;right: 20px;">提 交</el-button>
                <!-- <el-button>保 存</el-button> -->
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getUserInfo } from '@/utils/storage.js'
import {getCurrentTerm} from "@/api/member/term-manager";

import {applyANewCourse} from "@/api/course-application/course-application";
export default {
    data() {
      return {
        //可以取的学分
        labType: ['软件','硬件','网络'],
        time:['1-2','3-5','6-7','8-9','10-12','13-15'],
        startWeek:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
        endWeek:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
        weekdays: ['星期一','星期二','星期三','星期四','星期五','星期六','星期日'],
        //表单数据
        form: {
            teacherId: '',                      //教师id
            teacherName:'',                     //教师名称
            courseName: '',                     //课程名称
            labType:'',                         //实验室类型
            studentClass:'',                       //班级
            studentNum:'',                      //学生人数
            weekday:'',                         //申请星期几
            time:'',                            //节次
            startWeek:'',                       //起始周
            endWeek:'',                         //结束周
            operationName: '新增',              //操作名称
            courseExaminationName: '待审批',
            year:''
        }
      }
    },
    async created() {
        this.form.teacherId = getUserInfo().id
        this.form.teacherName = getUserInfo().name
        await getCurrentTerm().then(res => {
            this.form.year = res.data;
        });
    },  
    methods: {
        async submitInfo() {
            const result = await applyANewCourse(this.form)
            console.log(result)
            console.log(this.form)
        },

        onSubmit() {
            this.$confirm('您确定提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(
                async () => {
                   if(parseInt(this.form.startWeek) > parseInt(this.form.endWeek)){
                       this.$message({
                           type: 'error',
                           message: '起始周和结束周设计不合理!'
                       })
                   }else {
                       this.submitInfo(),
                           this.form.courseName = '',
                           this.form.labType = '',
                           this.form.studentClass = '',
                           this.form.studentNum = '',
                           this.form.time = '',
                           this.form.startWeek = '',
                           this.form.endWeek = '',
                           this.form.weekday  = '',
                           this.$message({
                               type: 'success',
                               message: '提交成功!'
                           })
                   }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                })        
            })
        }
    }
}
</script>

<style scoped>
    .root {
        width: 60vh;
    }
</style>