<!-- 课程申请列表项 -->
<template>
    <div>
        <div class="root" @click="openDialog()"> 
            <!-- 每一项 -->
            <div style="margin-left: 20px;">
                <el-avatar :src="avatarURL"></el-avatar>
            </div>
            <div style="margin-left: 10px;">
                <div  class="userName-font">
                    <slot name="userName">用户名</slot>
                </div>
                <div class="application-font">
                    申请报修<slot name="operation"></slot>实验室
                </div>
            </div>  
            <div class="right">
                <slot name = "repairStatusName">
                    <el-tag type="warning" size="mini" class="tag">
                        未维修
                    </el-tag>
                </slot>
                <div class="date-font">
                <slot name="dateTime">2023-01-05 12:12:25</slot>
                </div>
            </div>  
        </div>

        <!-- 对话框 -->
        <el-dialog :visible.sync="dialogFormVisible">
            <el-descriptions :title="title" :column="2" border>
                <el-descriptions-item label="实验室编号">{{ newData.labNum }}</el-descriptions-item>
                <el-descriptions-item label="教师名称">{{ newData.teacherName }}</el-descriptions-item>
                <el-descriptions-item label="报修日期">{{ newData.dateTime }}</el-descriptions-item>
                <el-descriptions-item label="故障描述">{{ newData.descriptionOfFault }}</el-descriptions-item>
                <el-descriptions-item label="维修说明">{{ newData.descriptionOfRepair }}</el-descriptions-item>
            </el-descriptions>

<!--            <el-descriptions title="修改前的数据" column="2" border style="margin-top: 20px;" v-if="newData.operationName === '修改'">
                <el-descriptions-item label="实验室编号">{{ newData.labNum }}</el-descriptions-item>
                <el-descriptions-item label="教师名称">{{ newData.teacherName }}</el-descriptions-item>
                <el-descriptions-item label="报修日期">{{ newData.dateTime }}</el-descriptions-item>
                <el-descriptions-item label="故障描述">{{ newData.descriptionOfFault }}</el-descriptions-item>
                <el-descriptions-item label="维修说明">{{ newData.descriptionOfRepair }}</el-descriptions-item>
            </el-descriptions>-->

<!--            <div slot="footer" class="dialog-footer" v-if="isShowButton === 'true'">
                <el-button @click="agreeOrNot('未通过')">不通过</el-button>
                <el-button type="primary" @click="agreeOrNot('已通过')">通 过</el-button>
            </div>-->

        </el-dialog>

    </div>
</template>

<script>
import avatarURL from '@/assets/avatar.jpg'
import {getRepairById} from "@/api/repair/application-repair";


export default {
    data() {
        return {
            dialogFormVisible: false,
            //标题
            title: '详情',
            //申请的新的数据
            newData: {},
            //原来的数据
            originalData: {},
            avatarURL:avatarURL,
        }
    },
    props: {
        //将课程申请的id传过来
        equipmentRepairId: {
            type: Number
        },
        //控制审批按钮是否显示
        isShowButton: {
            type: String
        },
        //控制实验室编号选择器是否可选
        selectActive: {
            type: String
        }
    },
    methods: {
        async getCourseInfoById() {
            const res = await getRepairById(this.newData.equipmentRepairId)
            if (res.data.code === 1) {
                this.originalData = res.data.data
            }
        },
        async openDialog() {
            //打开对话框，利用从父组件中传过来的id获取到相应的数据
            const result = await getRepairById(this.equipmentRepairId)
            this.newData = result.data.data
            console.log(this.isShowButton)
            this.getCourseInfoById()
            /*if (this.isShowButton === 'true') {
                switch(operationName) {
                    case '新增':
                        this.title = '申请新增'
                        break
                    case '修改':
                        this.title = '修改后的数据'
                        this.getCourseInfoById()
                        break
                }
            }*/
            this.dialogFormVisible = true
        },
        //是否同意教师的申请
       /* async agreeOrNot(name) {
            this.newData.courseExaminationName = name
            await examineACourse(this.newData).then(res=>{
                console.log(res);
                if(res.data.code===0){
                    this.$confirm(res.data.message, '通过失败', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        showCancelButton:false,
                        showClose:false
                    })
                }else{
                    this.$message({
                        type: 'success',
                        message: '分配通过成功！'
                    })
                }

            })
            this.dialogFormVisible = false
            this.$emit('refresh')//告知父组件更新页面
        }*/
    }
}
</script>

<style scoped>
    .root {
        position: relative;
        display: flex;
        margin-bottom: 12px;
        width: 100%;
        height: 80px;
        background-color: rgba(250, 249, 249, 0.978);
        border-radius: 7px;
        cursor: pointer;
    }
    .root > div {
        align-self: center;
    }
    .userName-font {
        font-size: small;
        color: rgba(0, 0, 0, 0.695);
        margin-bottom: 2%;
    }
    .application-font {
        font-size: medium;
        font-weight: 550;
        color: rgba(0, 0, 0, 0.77);
    }
    .right {
        position: absolute;
        right: 20px;
        text-align: right;
        right: 26px;
    }
    .tag {
        text-align: right;
        margin-bottom: 26%;
    }
    .date-font {
        font-size:x-small;
        color: rgba(0, 0, 0, 0.626);
    }
</style>