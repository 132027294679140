import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import Layout from '@/views/Layout.vue'
import Home from '@/views/Home.vue'
import StudentManager from '@/views/member/StudentManager.vue'
import TeacherManager from '@/views/member/TeacherManager.vue'
import TesterManager from '@/views/member/TesterManager.vue'
import CourseApplication from '@/views/course/CourseApplication.vue'
import CourseExamination from '@/views/course/CourseExamination.vue'
import BorrowExamination from '@/views/course/BorrowExamination.vue'
import TeacherCourse from '@/views/manager/TeacherCourse.vue'
import StudentLab from '@/views/manager/StudentLab.vue'
import TeacherCourseDetail from '@/views/manager/TeacherCourseDetail.vue'
import TeachingArrrangement from '@/views/course/TeachingArrangement.vue'
import SelectCourseCenter from '@/views/course/SelectCourseCenter.vue'
import SelectTerm from '@/views/term/Management.vue'
import TesterRepair from "@/views/course/TesterRepair.vue";
import ApplicationRepair from "@/views/repair/ApplicationRepair.vue";
import { checkLogin } from '@/api/login/login.js'
import { getUserInfo } from '@/utils/storage.js'
Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: "history",
    routes: [
        { 
          path: '/', 
          redirect: '/home'
        },
        { 
          path: '/login', 
          component: Login 
        },
        { 
          path: '/layout', 
          component: Layout, 
          children: [
            { path: '/home', component: Home},
            { path: '/student', component: StudentManager },
            { path: '/teacher', component: TeacherManager },
            { path: '/tester', component: TesterManager },
            { path: '/course/application', component: CourseApplication },
            { path: '/course/examination', component: CourseExamination },
            { path: '/borrow/examination', component: BorrowExamination },
            { path: '/teacher/course', component: TeacherCourse },
            { path: '/student/lab', component: StudentLab },
            { path: '/teacher/course/detail', component: TeacherCourseDetail },
            { path: '/teaching/arrangement', component: TeachingArrrangement },
            { path: '/select/course/center', component: SelectCourseCenter },
            { path: '/term/management', component: SelectTerm },
            { path: '/teacher/application/repair', component: ApplicationRepair },
            { path: '/tester/repair', component: TesterRepair },
          ]
        }

    ]
})

//配置游客可以访问的界面
const touristVisitUrl = ['/login']

//全局路由前置守卫
router.beforeEach( async (to, from, next) => {
  if (touristVisitUrl.includes(to.path)) {
    next()
  } else {
    const userInfo = getUserInfo()
    const result = await checkLogin(userInfo)
    if (result.data.code == 0) {
      next('/login')
      Vue.prototype.$message.error('登录超时，请重新登录！')
    }
    next()
  }
})

export default router