<!-- 课程申请列表项 -->
<template>
    <div>
        <div class="root" @click="openDialog()"> 
            <!-- 每一项 -->
            <div style="margin-left: 20px;">
                <el-avatar :src="avatarURL"></el-avatar>
            </div>
            <div style="margin-left: 10px;">
                <div  class="userName-font">
                    <slot name="userName">用户名</slot>
                </div>
                <div class="application-font">
                    请求<slot name="operation"></slot>一门实验课程 "<slot name="course"></slot>"
                </div>
            </div>  
            <div class="right">
                <slot name = "courseExaminationName">
                    <el-tag type="warning" size="mini" class="tag">
                        待审批
                    </el-tag>
                </slot>
                <div class="date-font">
                <slot name="dateTime">2023-01-05 12:12:25</slot>
                </div>
            </div>  
        </div>

        <!-- 对话框 -->
        <el-dialog :visible.sync="dialogFormVisible">
                <el-form ref="form" :model="form" label-width="100px" v-if="courseExaminationName !== '待审批'|| isShowButton === true "   >
                    <el-form-item label="课程名称：">
                        <el-input v-model="form.courseName" placeholder="请输入课程名称" disabled=disabled />
                    </el-form-item>
                    <el-form-item label="实验室类型：">
                        <el-select v-model="form.labType" placeholder="请选择实验室类型" style="width: 100%;" disabled=disabled>
                            <el-option v-for="item in labType" :label="item" :value="item" :key="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学生班级：">
                        <el-input v-model="form.studentClass" placeholder="请输入学生班级" disabled=disabled />
                    </el-form-item>
                    <el-form-item label="学生人数：">
                        <el-input v-model="form.studentNum" placeholder="请输入学生人数" disabled=disabled />
                    </el-form-item>
                    <el-form-item label="星期：">
                        <el-select v-model="form.weekday" placeholder="请选择星期" style="width: 100%;" disabled=disabled >
                            <el-option v-for="item in weekdays" :label="item" :value="item" :key="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="节次：">
                        <el-select v-model="form.time" placeholder="请选择节次" style="width: 100%;" disabled=disabled >
                            <el-option v-for="item in time" :label="item" :value="item" :key="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="起始周：">
                        <el-select v-model="form.startWeek" placeholder="请选择起始周" style="width: 100%;" disabled=disabled >
                            <el-option v-for="item in startWeek" :label="item" :value="item" :key="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结束周：">
                        <el-select v-model="form.endWeek" placeholder="请选择结束周" style="width: 100%;" disabled=disabled >
                            <el-option v-for="item in endWeek" :label="item" :value="item" :key="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="实验室编号：">
                        <el-input v-model="form.labNum" placeholder="请输入实验室编号" disabled=disabled />
                    </el-form-item>
                </el-form>
<!--                <el-descriptions-item label="实验室编号">
                    <div v-if="newData.labNum == null" style="color: red">
                        待分配
                    </div>
                    <div v-else>
                        {{ newData.labNum }}
                    </div>
                </el-descriptions-item>-->


            <el-form ref="form" :model="form" label-width="100px" v-if="courseExaminationName === '待审批' && isShowButton !== true " >
                <el-form-item label="课程名称：">
                    <el-input v-model="form.courseName" placeholder="请输入课程名称"  />
                </el-form-item>
                <el-form-item label="实验室类型：">
                    <el-select v-model="form.labType" placeholder="请选择实验室类型" style="width: 100%;" >
                        <el-option v-for="item in labType" :label="item" :value="item" :key="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="学生班级：">
                    <el-input v-model="form.studentClass" placeholder="请输入学生班级"  />
                </el-form-item>
                <el-form-item label="学生人数：">
                    <el-input type="Number" v-model="form.studentNum" placeholder="请输入学生人数" />
                </el-form-item>
                <el-form-item label="星期：">
                    <el-select v-model="form.weekday" placeholder="请选择星期" style="width: 100%;"  >
                        <el-option v-for="item in weekdays" :label="item" :value="item" :key="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="节次：">
                    <el-select v-model="form.time" placeholder="请选择节次" style="width: 100%;"  >
                        <el-option v-for="item in time" :label="item" :value="item" :key="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="起始周：">
                    <el-select v-model="form.startWeek" placeholder="请选择起始周" style="width: 100%;"  >
                        <el-option v-for="item in startWeek" :label="item" :value="item" :key="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="结束周：">
                    <el-select v-model="form.endWeek" placeholder="请选择结束周" style="width: 100%;"  >
                        <el-option v-for="item in endWeek" :label="item" :value="item" :key="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="实验室编号：">
                    <el-input v-model="form.labNum" placeholder="请输入实验室编号" disabled=disabled />
                </el-form-item>
                <el-form-item style="text-align: center;">
                    <el-button type="primary" @click="onUpdate" style="width: 140px; position: relative;right: 20px;">提 交</el-button>
                    <!-- <el-button>保 存</el-button> -->
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" v-if="isShowButton === 'true'">
                <el-button @click="agreeOrNot('未通过')">不通过</el-button>
                <el-button type="primary" @click="agreeOrNot('已通过')">通 过</el-button>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import {getCourseAppliactionById, updateApplication} from '@/api/course-application/course-application.js'
import { examineACourse } from '@/api/course-examination/course-examination.js'
import { getCourseById } from '@/api/course/course.js'
import avatarURL from '../assets/avatar.jpg'
import {getUserInfo} from "@/utils/storage";



export default {

    data() {
        return {
            dialogFormVisible: false,
            //标题
            title: '详情',
            //申请的新的数据
            newData: {},
            //原来的数据
            originalData: {},
            avatarURL:avatarURL,
            labType: ['软件','硬件','网络'],
            time:['1-2','3-5','6-7','8-9','10-12','13-15'],
            startWeek:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
            endWeek:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
            weekdays: ['星期一','星期二','星期三','星期四','星期五','星期六','星期日'],
            form:{
                courseApplicationId: '',
                teacherId: '',                      //教师id
                teacherName:'',                     //教师名称
                courseName: '',                     //课程名称
                labType:'',                         //实验室类型
                studentClass:'',                       //班级
                studentNum:'',                      //学生人数
                weekday:'',                         //申请星期几
                time:'',                            //节次
                startWeek:'',                       //起始周
                endWeek:'',                         //结束周
                operationName: '',              //操作名称
                courseExaminationName: '',
                year:'',
                labNum:''
            },
        }
    },
    props: {
        //将课程申请的id传过来
        courseApplicationId: {
            type: String
        },
        operationName:{
            type:String
        },
        courseExaminationName:{
            type:String
        },
        //控制审批按钮是否显示
        isShowButton: {
            type: String
        },
        //控制实验室编号选择器是否可选
        selectActive: {
            type: String
        }

    },
    methods: {
        async getCourseInfoById() {
            const res = await getCourseById(this.newData.courseId)
            if (res.data.code === 1) {
                this.originalData = res.data.data
            }
        },
        async openDialog() {
            //打开对话框，利用从父组件中传过来的id获取到相应的数据
            const result = await getCourseAppliactionById(this.courseApplicationId)
            this.newData = result.data.data
            const operationName = result.data.operationName
            console.log(this.isShowButton)
            this.form = result.data.data;
            if(this.form.labNum === null){
                this.form.labNum = '待分配'
            }
            console.log(getUserInfo().name )
            if (this.isShowButton === 'true') {
                switch(operationName) {
                    case '新增':
                        this.title = '申请新增'
                        break
                    case '修改':
                        this.title = '修改后的数据'
                        this.getCourseInfoById()
                        break
                    case '删除':
                        this.title = '申请删除'
                        break
                    default:
                        break
                }
            }

            this.dialogFormVisible = true
        },
        async updateInfo() {
            this.form.labNum = null
            const result = await updateApplication(this.form)
            console.log(result)
            console.log(this.form)
        },
        async onUpdate(){
            this.form.courseApplicationId = this.courseApplicationId
            console.log(this.form)
            this.$confirm('您确定提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(
                async () => {
                    if(parseInt(this.form.startWeek) > parseInt(this.form.endWeek)){
                        this.$message({
                            type: 'error',
                            message: '起始周和结束周设计不合理!'
                        })
                    }else {
                        this.updateInfo(),
                            this.$message({
                                type: 'success',
                                message: '提交成功!'
                            })
                        this.dialogFormVisible = false
                    }
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                })
            })
        },
        //是否同意教师的申请
        async agreeOrNot(name) {
            this.newData.courseExaminationName = name
            this.newData.labNum = null;
            await examineACourse(this.newData).then(res=>{
                console.log(res);
                if(res.data.code===0){
                    this.$confirm(res.data.message, '通过失败', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        showCancelButton:false,
                        showClose:false
                    })
                }else{
                    this.$message({
                        type: 'success',
                        message: '分配通过成功！'
                    })
                }

            })
            this.dialogFormVisible = false
            this.$emit('refresh')//告知父组件更新页面
        }
    }
}
</script>

<style scoped>
    .root {
        position: relative;
        display: flex;
        margin-bottom: 12px;
        width: 100%;
        height: 80px;
        background-color: rgba(250, 249, 249, 0.978);
        border-radius: 7px;
        cursor: pointer;
    }
    .root > div {
        align-self: center;
    }
    .userName-font {
        font-size: small;
        color: rgba(0, 0, 0, 0.695);
        margin-bottom: 2%;
    }
    .application-font {
        font-size: medium;
        font-weight: 550;
        color: rgba(0, 0, 0, 0.77);
    }
    .right {
        position: absolute;
        right: 20px;
        text-align: right;
        right: 26px;
    }
    .tag {
        text-align: right;
        margin-bottom: 26%;
    }
    .date-font {
        font-size:x-small;
        color: rgba(0, 0, 0, 0.626);
    }
</style>