import requst from '@/utils/request.js'

// 查询实验员信息
export const getTestersInfo = (queryTesterParam) => {
    return requst.post('/testers', queryTesterParam)
}

// 新增一条实验员信息
export const insertTesterInfo = (form) => {
    return requst.post('/tester', form)
}   

//根据id删除实验员信息
export const deleteTesterInfo = (testerId) => {
    return requst.delete('/testers/' + testerId)
}

//根据id查询实验员信息
export const getTesterInfoById = (testerId) => {
    return requst.get('/get/tester?testerId=' + testerId)
}

//修改实验员信息（后端根据id进行修改）
export const updateTesterInfoById = (form) => {
    return requst.put('/modify/tester', form)
}

//获取所有实验员的信息
export const getAllTester = () => {
    return requst.get('/get/testers')
}