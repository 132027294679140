import requst from '@/utils/request.js'

//查询所有审批的数据
export const getBorrowExamination = () => {
    return requst.get('/borrow/examination')
}

//审批不通过记录
export const rejectStuBorrow = (labBorrowingId) => {
    return requst.put('/borrow/examination/reject?labBorrowingId=' + labBorrowingId)
}

//审批通过记录
export const agreeStuBorrow = (labBorrowingId) => {
    return requst.put('/borrow/examination/agree?labBorrowingId=' + labBorrowingId)
}